

























import { Component, Mixins } from "vue-property-decorator";

// Utilities
import { AuthMixin } from "@mixins";

/** Delay before logging out (seconds) */
const logoutDelay = 2000;

@Component
export default class Logout extends Mixins(AuthMixin) {
  /** Logout timeout reference */
  logoutTimeout: number | undefined = undefined;

  mounted(): void {
    // Log the user out after a brief interval to let them cancel the operation
    this.logoutTimeout = setTimeout(() => {
      this.logout();
    }, logoutDelay);
  }

  /**
   * Cancel the logout and return user to previous path
   */
  cancelLogout(): void {
    clearTimeout(this.logoutTimeout);

    this.$router.go(-1);
  }

  /**
   * Log the user out
   */
  logout(): void {
    clearTimeout(this.logoutTimeout);

    this.resetAuth();

    // NOTE: Must wait before redirecting to allow cleaning up auth state (used by router)
    setTimeout(() => {
      this.$router.replace("/");
    }, 10);
  }
}
